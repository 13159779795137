// eslint-disable-next-line no-undef
__webpack_public_path__ = new URL(
  new URL(document.currentScript.src).pathname
    .split('/')
    .slice(0, -1)
    .join('/') + '/',
  document.currentScript.src
).href;

import './i18n';
import React, {lazy, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';

import ProgressInfinite from './components/ProgressInfinite.jsx';

import config from './config';
import {getMe} from './bwApi';
import {startSentry} from './startSentry';

import {QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {queryClient} from './queryClient';

/* eslint-disable no-undef, no-console */
if (
  process.env.ENABLE_MSW === 'true' &&
  process.env.NODE_ENV === 'development'
) {
  import('./apiMocks/browser').then(({worker}) => {
    console.warn(
      '**** enabling mock service worker to serve api requests ****'
    );
    worker.start();
  });
}
/* eslint-enable no-undef, no-console */

import './index.css';
import './flexbox.css';

const App = lazy(() =>
  import(/* webpackChunkName: 'app' */ './components/App.jsx')
);

import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk';
import launchDarklyLogger from './launchDarklyLogger';

(async () => {
  const {
    id,
    client: {id: clientId, parentId: parentClientId},
    uiLanguage
  } = await getMe();

  startSentry(id);

  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.LD_CLIENT_SIDE_ID,
    user: {
      key: id,
      custom: {
        clientId,
        parentClientId: parentClientId === -1 ? clientId : parentClientId
      }
    },
    options: {
      logger: launchDarklyLogger()
    }
  });

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Suspense fallback={<ProgressInfinite />}>
      <QueryClientProvider client={queryClient}>
        <LDProvider>
          <Router>
            <App uiLanguage={uiLanguage} />
          </Router>
        </LDProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  );
})();
